import React, { useEffect, useMemo } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { useLocation } from '@shared/components/router';

import { styles } from './3d-secure-form.styles';
export interface LoginProps extends AppWithStyles<typeof styles> {}

const Payments3DSecureForm: React.FC<LoginProps> = () => {
  useEffect(() => {
    // @ts-ignore
    document?.getElementById('wealthstack-3d-secure-form')?.submit();
  });
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const secureUrl = query.get('url') || '';
  const secureJWT = query.get('jwt') || '';
  const bin = query.get('bin') || '';

  return (
    <form id="wealthstack-3d-secure-form" name="devicedata" method="POST" action={secureUrl}>
      <input type="hidden" name="JWT" value={secureJWT} />
      <input type="hidden" name="Bin" value={bin} />
    </form>
  );
};

export default appWithStyles(styles)(Payments3DSecureForm);
